.wordSave {
    float: right;
    margin-bottom: 16px;
    margin-top: 16px;
    margin-right: 10px;
}

.wordCancel {
    float: right;
    margin-bottom: 16px;
    margin-top: 16px;
    margin-right: 10px;
}