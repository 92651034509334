.editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
}
.addVideo {
    float: right;
    margin-bottom: 16px;
    margin-top: 16px;
    margin-right: 10px;
}

.videoTable td {
    font-size: 17px;
}
.videoTable th {
    font-size: 18px;
}