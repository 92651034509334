.off-rate {
  background-color: white;
  margin-right: 10px !important;
}

.tag-button-group {
  float: right;
  margin-bottom: 16px;
  margin-top: 16px;
  margin-right: 40px;
  transition-duration: 1s;
}

.tag-button {
  margin-left: 10px;
  margin-top: 3px;
}

.off-tag {
  background-color: #627fce;
  border: 2px solid;
  border-radius: 8px;
  font-weight: bold;
  color: white;
  font-size: 13px;
}