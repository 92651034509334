.newNotification {
  float: right;
  margin-bottom: 16px;
  margin-top: 16px;
  margin-right: 10px;
}

.multiline-cell {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: pre-line; 
  word-wrap: break-word;
}