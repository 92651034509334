.upload-img {
  width: 190px !important;
}

label[for=file-input] {
  font-family: cursive;
  font-size: 17;
}

.MyPracticeDetailTable .ant-table-tbody tr td:nth-child(4) {
  padding: 0px !important;
}

.MyPracticeDetailTable .ant-table-tbody tr td:nth-child(4) div {
  padding-left: 0px !important;
}

.PracticeDetailInnerTable .ant-table-tbody tr td {
  text-align: center !important;
}