.paid-cell {
  background-color: #48b931;
  border: 2px solid;
  border-radius: 8px;
  font-weight: bold;
  color: white;
  font-size: 14px;
}

.unpaid-cell {
  background-color: #b93131;
  border: 2px solid;
  border-radius: 8px;
  font-weight: bold;
  color: white;
  font-size: 14px;
}