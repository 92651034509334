.ant-layout-sider {
    /* margin-top: 10px; */
    border-radius: 0px 15px 0px 15px;
    /* margin-left: 10px; */
    overflow: hidden;
}

.my_sider {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.ant-menu-submenu-title {
    font-size: 16px;
}

.ant-menu-item {
    font-size: 16px;
}