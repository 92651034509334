.editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
}

.addRound {
  float: right;
  margin-bottom: 16px;
  margin-top: 16px;
  margin-right: 10px;
}

.delete {
  float: right;
  margin-bottom: 16px;
  margin-top: 16px;
}

.ant-table {
  margin-left: 0px !important;
}

th {
  text-align: center !important;
  font-size: 16px;
}

td {
  text-align: center !important;
  font-size: 16px;
}

table {
  border-collapse: collapse !important;
}

.version {
  margin-left: 10px !important;
  margin-top: 13px !important;
}

.updateButton {
  margin-left:15px;
  margin-top: 16px;
}

.MyPracticeTable .ant-table-tbody tr td:nth-child(4) {
  padding-left: 0px !important;
  vertical-align: top;
}

.MyPracticeTable .ant-table-tbody tr td:nth-child(5) {
  padding-left: 0px !important;
  vertical-align: top;
}